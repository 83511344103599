//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MyPagination from '../../my-pagination.vue'
export default {
    props: [],
    data () {
        return {
            data: [{
                id: '100',
                label: '一级 1',
                children: [{
                    id: '110',
                    label: '二级 1-1',
                    children: [{
                        id: '111',
                        label: '三级 1-1-1'
                    }]
                }]
            }, {
                id: '200',
                label: '一级 2',
                children: [{
                    id: '210',
                    label: '二级 2-1',
                    children: [{
                        id: '211',
                        label: '三级 2-1-1'
                    }]
                }, {
                    id: '220',
                    label: '二级 2-2',
                    children: [{
                        id: '221',
                        label: '三级 2-2-1'
                    }]
                }]
            }, {
                id: '300',
                label: '一级 3',
                children: [{
                    id: '310',
                    label: '二级 3-1',
                    children: [{
                        id: '311',
                        label: '三级 3-1-1'
                    }]
                }, {
                    id: '320',
                    label: '二级 3-2',
                    children: [{
                        id: '321',
                        el: '三级 3-2-1'
                    }]
                }]
            }],

            model: []
        }
    },
    methods: {
        nodeClickHandler(node) {
            console.log(node)
        }
    },
    mounted: function() {
        
    }
}
